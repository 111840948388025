import React, { createContext, useContext, useState } from 'react';
import constants from './constants';

const ConstantsContext = createContext();

const ConstantsProvider = ({ children }) => {
   return (
      <ConstantsContext.Provider value={constants}>
         {children}
      </ConstantsContext.Provider>
   );
};
const useConstants = () => useContext(ConstantsContext);
export { ConstantsProvider, useConstants };

//
// GlobalStateContext.js
const GlobalStateContext = createContext();

const GlobalStateProvider = ({ children }) => {
   const [globalState, setGlobalState] = useState({
      //Global State Values ie isLoggedIn: false,
   });

   const updateGlobalState = (updates) => {
      setGlobalState({
         ...globalState,
         ...updates,
      });
   };

   return (
      <GlobalStateContext.Provider
         value={{
            globalState,
            updateGlobalState,
         }}
      >
         {children}
      </GlobalStateContext.Provider>
   );
};
const useGlobalState = () => useContext(GlobalStateContext);
export { GlobalStateProvider, useGlobalState };
