import React, { useContext, createContext } from 'react';

const NavigationContext = createContext();

export const NavigationProvider = ({ children, handleNavigate }) => {
   return (
      <NavigationContext.Provider
         value={{
            handleNavigate,
         }}
      >
         {children}
      </NavigationContext.Provider>
   );
};

export const useNavigationContext = () => {
   return useContext(NavigationContext);
};
