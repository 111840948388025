let isPlaying = false; // Track whether a sound is currently playing
const audioInstances = {}; // Object to cache audio instances

const soundMap = {
   click: '/audio/buttonSounds/click.ogg',
   hover: '/audio/buttonSounds/hover.mp3',
};

// Preload audio files and store them in audioInstances
const preloadAudio = () => {
   for (const soundType in soundMap) {
      const audio = new Audio(soundMap[soundType]);
      audioInstances[soundType] = audio;

      // Set up the 'ended' event to reset the isPlaying flag when audio finishes
      audio.onended = () => {
         isPlaying = false; // Mark sound as no longer playing
      };
   }
};

// Function to play sounds
const PlaySounds = (soundType) => {
   const audio = audioInstances[soundType];

   if (audio) {
      if (isPlaying) {
         // If a sound is already playing, stop it before playing the new sound
         audio.pause();
         audio.currentTime = 0; // Reset the current time to the beginning
      } else {
         isPlaying = true; // Mark sound as playing
      }

      // Play the sound
      audio.play().catch((error) => {
         console.error(`Error playing sound: ${error}`);
      });
   } else {
      console.warn(`Sound not found for type: ${soundType}`);
   }
};

// Call preloadAudio to load sounds into memory when your application starts
preloadAudio();

export default PlaySounds;
