import React, {
   createContext,
   useContext,
   useEffect,
   useState,
   useCallback,
} from 'react';
import PlaySounds from './PlaySounds';

const ImageContext = createContext();

export const useImageContext = () => {
   return useContext(ImageContext);
};

export const ImageProvider = ({ children }) => {
   // Storing all images and tags in the context
   const [imagePaths, setImagePaths] = useState([]);
   const [imageTags, setImageTags] = useState([]);
   const [imageIndexes, setImageIndexes] = useState([]);

   // Stores highResSrc, lowResSrc, srcSet, and sizes
   const [loadedImageData, setLoadedImageData] = useState([]);
   const [loadedThumbnailData, setLoadedThumbnailData] = useState([]);

   // Finding current image based on index
   const [currentImage, setCurrentImage] = useState(null);
   const [currentSrcSet, setCurrentSrcSet] = useState(null);
   const [currentIndex, setCurrentIndex] = useState(null);
   const [currentTags, setCurrentTags] = useState(null);
   const [currentMagnifier, setCurrentMagnifier] = useState(null);

   // Rendering flags
   const [isModalShown, setIsModalShown] = useState(false);
   const [isThumbnailShown, setIsThumbnailShown] = useState(false);

   const [isMagnifierControlsShown, setIsMagnifierControlsShown] =
      useState(false);

   const updateImagePaths = useCallback((paths) => {
      setImagePaths(paths);
   }, []);

   const updateImageTags = useCallback((tags) => {
      setImageTags(tags);
   }, []);

   const updateCurrentIndex = useCallback((index) => {
      setCurrentIndex(index);
   }, []);

   const updateLoadedImageData = useCallback((data) => {
      setLoadedImageData(data);
   }, []);

   const updateLoadedThumbnailData = useCallback((data) => {
      setLoadedThumbnailData(data);
   }, []);

   useEffect(() => {
      console.log('FUCKER', loadedImageData);
   }, [loadedImageData]);

   const updateThumbnailVisibility = useCallback((visibility) => {
      setIsThumbnailShown(visibility);
   }, []);

   const showModal = (image, hires, srcset = [], tags) => {
      setCurrentImage(image);
      setCurrentMagnifier(hires);
      setCurrentSrcSet(srcset);
      if (tags) {
         //Only update if tags exist, else use original tag for image
         setCurrentTags(tags);
      }
      setIsModalShown(true);
      setIsMagnifierControlsShown(true);

      document.body.style.overflow = 'hidden';
   };

   const closeModal = () => {
      if (document.fullscreenElement) {
         document.exitFullscreen();
      } else {
         setIsModalShown(false);
         setCurrentImage(null);
         setCurrentTags(null);
         setCurrentIndex(null);
         updateThumbnailVisibility(false);
         setIsMagnifierControlsShown(false);

         document.body.style.overflow = 'auto';
      }

      PlaySounds('click');
   };

   const prevModal = () => {
      if (currentIndex > 0) {
         setCurrentIndex(currentIndex - 1);
      } else {
         setCurrentIndex(imagePaths.length - 1);
      }

      PlaySounds('click');
   };

   const nextModal = () => {
      if (currentIndex < imagePaths.length - 1) {
         setCurrentIndex(currentIndex + 1);
      } else {
         setCurrentIndex(0);
      }

      PlaySounds('click');
   };

   useEffect(() => {
      console.log('PUSSSSSS', loadedImageData);
   }, [loadedImageData]);

   useEffect(() => {
      if (currentIndex >= 0 && currentIndex < loadedImageData.length) {
         const image = loadedImageData[currentIndex]?.lowResSrc || ''; // Get the image at the current index
         const hires = loadedImageData[currentIndex]?.highResSrc || ''; // Get the hires image for magnifier
         const srcset = loadedImageData[currentIndex]?.srcSet || ''; // Get the srcset for the image
         const tags = imageTags ? imageTags[currentIndex] : null; // Get the tags for the image

         if (image) {
            showModal(image, hires, srcset, tags); // Call showModal with the current image and tags
         }
      }
   }, [currentIndex, loadedImageData, imageTags]);

   useEffect(() => {
      setImageIndexes(loadedImageData.length);
   }, [loadedImageData.length]);

   return (
      <ImageContext.Provider
         value={{
            // Overall paths and tags
            imagePaths,
            imageTags,
            imageIndexes,

            // Objects containing low-res, high-res, and srcsets
            loadedImageData,
            loadedThumbnailData,

            // Current image, index, and tags
            currentImage,
            currentSrcSet,
            currentIndex,
            currentTags,
            currentMagnifier,

            // Rendering flags
            isModalShown,
            isThumbnailShown,
            isMagnifierControlsShown,

            // Functions to update the overall paths and tags
            updateImagePaths,
            updateImageTags,

            // Functions to update the object containing low-res, high-res, and srcsets
            updateLoadedImageData,
            updateLoadedThumbnailData,

            // Functions to update the state
            showModal,
            closeModal,
            prevModal,
            nextModal,
            updateCurrentIndex,

            // Functions to update the rendering flags
            updateThumbnailVisibility,
         }}
      >
         {children}
      </ImageContext.Provider>
   );
};
